import { computed, ref, shallowRef, watch } from 'vue'
import {
  createOrganizationFolder,
  createOrganizationSpace,
  deleteFolderProject,
  deleteOrganizationFolder,
  deleteOrganizationSpace,
  listOrganizationSpace,
  listOrganizationSubFolderAndProject,
  listProjectGraphicalPreview,
  moveFolder,
  renameFolder,
  updateOrganizationFolder,
  updateOrganizationSpace,
} from '@elecmap/api/src/cad/space'
import {
  createProject,
  deleteProject,
  updateProject,
  renameProject,
  moveProject,
  copyProject,
} from '@elecmap/api/src/cad/project'
import type {
  CadFolderShortVo,
  CadProjectShortVo,
  CreateFolderCommand,
  CreateSpaceCommand,
  MoveFolderCommand,
  RenameFolderCommand,
  UpdateFolderCommand,
  UpdateSpaceCommand,
} from '@elecmap/api/src/cad/space.d'
import type {
  CreateProjectCommand,
  MoveProjectCommand,
  UpdateProjectCommand,
} from '@elecmap/api/src/cad/project.d'
import { defineStore } from 'pinia'
import { message } from 'ant-design-vue'
import { t } from '@/i18n'
import { useRoute } from 'vue-router'
import { useStorageRef } from '@elecmap/shared/src/composable/use-storage-ref'

export const useSpaceStore = defineStore('spaceStore', () => {
  const route = useRoute()

  const orgSpaceList = ref<CadFolderShortVo[]>([])
  const orgFolderList = ref<CadFolderShortVo[]>([])
  const orgProjectList = ref<CadProjectShortVo[]>([])
  const currentSpace = ref<CadFolderShortVo>()
  const previewMap = ref<Record<number, string>>({})
  const frameMap = ref<Record<number, number>>({})

  const navs = useStorageRef<CadFolderShortVo[]>('workbench-navs', [])
  const currentFolder = computed(() => {
    if (navs.value.length) {
      return navs.value[navs.value.length - 1]
    }
    return null
  })

  const success = () => message.success(t('message.operatedSuccessfully'))

  async function listOrgSpace() {
    const d = await listOrganizationSpace()
    orgSpaceList.value = d
  }

  async function listOrgSubFolderProject() {
    if (currentFolder.value) {
      const data = await listOrganizationSubFolderAndProject(currentFolder.value.id)
      orgFolderList.value = data.subFolderList ?? []
      orgProjectList.value = data.projectList ?? []
      if (data.projectList?.length) {
        const previews = await listProjectGraphicalPreview(data.projectList.map(item => item.id))
        previews.forEach(p => {
          previewMap.value[p.projectId] = p.graphicalPreview
          if (p.frameId) {
            frameMap.value[p.projectId] = p.frameId
          }
        })
      }
    }
  }

  async function createOrgSpace(cmd: CreateSpaceCommand) {
    await createOrganizationSpace(cmd)
    success()
    listOrgSpace()
  }

  async function updateOrgSpace(cmd: UpdateSpaceCommand) {
    await updateOrganizationSpace(cmd)
    success()
    listOrgSpace()
  }

  async function deleteOrgSpace(id: number) {
    await deleteOrganizationSpace(id)
    success()
    listOrgSpace()
  }

  async function createOrgFolder(cmd: CreateFolderCommand) {
    await createOrganizationFolder(cmd)
    success()
    listOrgSubFolderProject()
  }

  async function updateOrgFolder(cmd: UpdateFolderCommand) {
    await updateOrganizationFolder(cmd)
    success()
    listOrgSubFolderProject()
  }

  async function renameOrgFolder(cmd: RenameFolderCommand) {
    await renameFolder(cmd)
    success()
    listOrgSubFolderProject()
  }

  async function deleteOrgFolder(id: number) {
    await deleteOrganizationFolder(id)
    success()
    listOrgSubFolderProject()
  }

  async function createOrgProject(data: CreateProjectCommand) {
    await createProject(data)
    success()
    listOrgSubFolderProject()
  }

  async function renameOrgProject(data: any) {
    await renameProject(data)
    success()
    listOrgSubFolderProject()
  }

  async function copyOrgProject(data: any) {
    await copyProject({ projectId: data.id, name: data.name })
    success()
    listOrgSubFolderProject()
  }

  async function moveOrgFolder(data: MoveFolderCommand) {
    await moveFolder(data)
    success()
    listOrgSubFolderProject()
  }

  async function updateOrgProject(data: UpdateProjectCommand) {
    await updateProject(data)
    listOrgSubFolderProject()
  }

  async function deleteOrgProject(id: number) {
    await deleteProject(id)
    listOrgSubFolderProject()
  }

  async function moveOrgProject(cmd: MoveProjectCommand) {
    await moveProject(cmd)
    listOrgSubFolderProject()
  }

  function setCurrentFolder(folder: CadFolderShortVo, exist = false) {
    if (!exist) {
      navs.value.push(folder)
      return
    }
    const index = navs.value.findIndex(n => n.id === folder.id)
    if (index !== -1) {
      navs.value = navs.value.slice(0, index + 1)
    }
  }

  watch(
    () => currentFolder.value,
    () => {
      listOrgSubFolderProject()
    },
    {
      immediate: true,
    },
  )

  watch(
    () => currentSpace.value,
    cs => {
      if (!cs) return
      if (!navs.value.length) {
        navs.value = [cs]
      }
    },
  )

  function init() {
    listOrgSpace().then(() => {
      if (route?.path === '/workbench/index') {
        const index = orgSpaceList.value.findIndex(o => o.id === navs.value[0]?.id)
        currentSpace.value = index === -1 ? orgSpaceList.value[0] : orgSpaceList.value[index]
      }
    })
  }

  return {
    orgSpaceList,
    currentSpace,
    orgFolderList,
    previewMap,
    frameMap,
    orgProjectList,
    navs,
    currentFolder,

    listOrgSpace,
    listOrgSubFolderProject,
    init,
    createOrgSpace,
    updateOrgSpace,
    deleteOrgSpace,
    createOrgFolder,
    updateOrgFolder,
    renameOrgFolder,
    renameOrgProject,
    copyOrgProject,
    moveOrgFolder,
    moveOrgProject,
    deleteOrgFolder,
    createOrgProject,
    deleteOrgProject,
    updateOrgProject,
    setCurrentFolder,
  }
})
