import http from '../client'
import type { CreateFrameCommand, ListFrameQuery, PageFrame, UpdateFrameCommand } from './frame.d'

export function createFrame(data: CreateFrameCommand) {
  return http<PageFrame>({
    url: '/cad/api/frame/create-frame',
    data,
  })
}

export function createStandardFrame(data: CreateFrameCommand) {
  return http<PageFrame>({
    url: '/cad/api/frame/create-standard-frame',
    data,
  })
}

export function deleteFrame(id: number) {
  return http<PageFrame>({
    url: '/cad/api/frame/delete-frame',
    data: { id },
  })
}

export function listFrame(data?: ListFrameQuery) {
  return http<PageFrame[]>({
    url: '/cad/api/frame/list-frame',
    data: data ?? {},
  })
}

export async function queryFrame(id: number) {
  try {
    const data = await http<PageFrame>({
      url: '/cad/api/frame/query-frame',
      data: { id },
    })
    return data
  } catch {
    return null
  }
}

export function updateFrame(data: UpdateFrameCommand) {
  return http<PageFrame>({
    url: '/cad/api/frame/update-frame',
    data,
  })
}

export function listFrameContent(ids: number[]) {
  return http<PageFrame[]>({
    url: '/cad/api/frame/list-frame-content',
    data: { ids },
  })
}

export function listFramePreview(ids: number[]) {
  return http<PageFrame[]>({
    url: '/cad/api/frame/list-frame-preview',
    data: { ids },
  })
}

export function renameFrame(data: { id: number; name: string }) {
  return http<PageFrame>({
    url: '/cad/api/frame/rename-frame',
    data,
  })
}

export function copyFrame(id: number) {
  return http<PageFrame>({
    url: '/cad/api/frame/copy-frame',
    data: { id },
  })
}
