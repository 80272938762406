import { listFramePreview } from '@elecmap/api/src/cad/frame'
import { debounce } from 'lodash-es'
import { computed, ref } from 'vue'

export const cachedFramePreview = ref<Record<number, string>>({})

let idList: number[] = []

const getFrame = debounce(async () => {
  if (idList.length) {
    const data = await listFramePreview(idList)
    data.forEach(d => {
      if (d.graphicalPreview) {
        cachedFramePreview.value[d.id] = d.graphicalPreview
      }
    })
    idList = []
  }
}, 100)

export async function getFrameById(id?: number) {
  if (!id) return
  if (idList.includes(id)) return
  idList.push(id)
  getFrame()
}
