<template>
  <div
    class="project-item"
    :class="{ 'with-path': showPath }"
    @dblclick="toDesign(item)"
    tabindex="1"
  >
    <div class="preview" v-html="props.graphicalPreview"></div>
    <div class="preview absolute top-0 left-0" v-html="framePreview"></div>
    <Menu :menus="projectMenus" @click="key => handleProjectMenuClick(key, item)">
      <Icon code="xe719;" tabindex="1" class="more" :size="18" @click.stop=""></Icon>
    </Menu>
    <div class="item-name flex">
      <span>{{ item.name }}</span>
      <div v-if="showPath" class="item-path">{{ path }}</div>
      <div class="star" @click="emit('star')">
        <star-filled style="color: rgb(250, 219, 20)" v-if="item.favoriteFlag" />
        <star-outlined class="unstarred" v-else />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { Modal, Menu } from '@elecmap/elecmap-ui'
import type { EmMenuItem } from '@elecmap/elecmap-ui/lib/menu/types'
import { Icon, renderIcon } from '@elecmap/icon'
import { listFramePreview } from '@elecmap/api/src/cad/frame'
import type { CadProjectShortVo } from '@elecmap/api/src/cad/space.d'
import { isDev } from '@elecmap/api/src/env'
import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons-vue'
import { cachedFramePreview, getFrameById } from '@/store/frame'

const props = withDefaults(
  defineProps<{
    item: CadProjectShortVo
    graphicalPreview?: string
    frameId?: number
    permission?: boolean
    showPath?: boolean
  }>(),
  {
    permission: true,
    showPath: false,
  },
)

const framePreview = computed(() =>
  props.frameId ? cachedFramePreview.value[props.frameId] : undefined,
)

watch(
  () => props.frameId,
  id => {
    if (!id) return
    getFrameById(id)
  },
  { immediate: true },
)

const path = computed(() => {
  if (!props.showPath) return ''
  return props.item.parentFolderList.map(pf => pf.name).join(' > ')
})
const emit = defineEmits<{
  (event: 'rename', payload: { id: number; name: string }): void
  (event: 'copy', payload: { id: number; name: string }): void
  (event: 'delete'): void
  (event: 'permission'): void
  (event: 'move'): void
  (event: 'star'): void
}>()

const projectMenus: EmMenuItem[][] = [
  [
    {
      name: '开始设计',
      key: 'toDesign',
      icon: renderIcon({ type: 'cmStart' }),
    },
  ],
  [
    {
      name: '重命名',
      key: 'rename',
      icon: renderIcon({ type: 'cmEdit' }),
    },
    {
      name: '权限',
      key: 'permission',
      icon: renderIcon({ type: 'cmPermission' }),
    },
    {
      name: '复制',
      key: 'copy',
      icon: renderIcon({ type: 'cmCopy' }),
    },
    {
      name: '移动',
      key: 'move',
      icon: renderIcon({ type: 'cmMove' }),
    },
    {
      name: '导出',
      key: 'export',
      icon: renderIcon({ type: 'cmExport' }),
    },
    {
      name: '分享协作',
      key: 'share',
      icon: renderIcon({ type: 'cmShare' }),
    },
  ].filter(m => {
    return m.key !== 'permission' || props.permission !== false
  }),
  [
    {
      name: '删除',
      key: 'delete',
      icon: renderIcon({ type: 'cmDelete' }),
    },
  ],
]

function handleRenameProject(project: CadProjectShortVo) {
  Modal.form({
    width: '520px',
    title: `重命名-${project.name}`,
    formItems: [
      {
        label: '名称',
        placeholder: '请输入名称',
        type: 'Input',
        key: 'name',
        rules: [
          {
            required: true,
            message: '请输入名称',
          },
        ],
      },
    ],
    formState: reactive({ keywords: { ...project }, tags: {} }),
    okText: '确认',
    onSubmit: ({ keywords }: any) => {
      emit('rename', { ...keywords })
    },
  })
}

function handleCopyProject(project: CadProjectShortVo) {
  Modal.form({
    width: '520px',
    title: `复制-${project.name}`,
    formItems: [
      {
        label: '名称',
        placeholder: '请输入名称',
        type: 'Input',
        key: 'name',
        rules: [
          {
            required: true,
            message: '请输入名称',
          },
        ],
      },
    ],
    formState: reactive({ keywords: { ...project, name: `${project.name} - 副本` }, tags: {} }),
    okText: '确认',
    onSubmit: ({ keywords }: any) => {
      emit('copy', { ...keywords })
    },
  })
}

function handleDeleteProject(project: CadProjectShortVo) {
  Modal.confirm({
    title: `删除-${project.name}`,
    content: [
      '请确认您要删除这个项目。',
      '这个项目内相关信息将会被永久删除。',
      '这是一个不可逆的操作，请谨慎对待。',
    ].join('<br />'),
    onOk: () => {
      emit('delete')
    },
  })
}

function toDesign(p: CadProjectShortVo) {
  window.location.href = isDev ? `http://127.0.0.1:8083?id=${p.id}` : `/design?id=${p.id}`
}

function handleProjectMenuClick(key: string, item: CadProjectShortVo) {
  switch (key) {
    case 'toDesign':
      toDesign(item)
      break
    case 'rename':
      handleRenameProject(item)
      break
    case 'copy':
      handleCopyProject(item)
      break
    case 'permission':
      emit('permission')
      break
    case 'move':
      emit('move')
      break
    case 'delete':
      handleDeleteProject(item)
      break
  }
}
</script>

<style lang="less">
.project-item {
  position: relative;
  cursor: pointer;
  width: 280px;
  height: 200px;
  margin-right: 36px;
  margin-bottom: 52px;
  &.with-path {
    margin-bottom: 58px;
  }
  &:focus {
    .preview {
      border: 2px solid #268feb;
    }
  }
  &.create {
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.15);
    // box-shadow: 1px 1px 2px 2px rgb(240, 240, 240);
    border-radius: 4px;
    transition: box-shadow 0.2s;
    .text {
      margin-right: 4px;
    }
  }
  .preview {
    // position: relative;
    // box-shadow: 1px 1px 2px 2px rgb(240, 240, 240);
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding: 3px;
    transition: box-shadow 0.2s;
  }
  .more {
    position: absolute;
    right: 3px;
    top: 3px;
    cursor: pointer;
    transition: opacity 0.18s ease-in-out;
    opacity: 0;
  }

  .item-name {
    margin-top: 8px;
    .star {
      margin-left: 4px;
      .unstarred {
        transition: opacity 0.18s ease-in-out;
        opacity: 0;
      }
    }
  }

  &:hover {
    .more {
      opacity: 1;
    }
    .star {
      .unstarred {
        opacity: 1;
      }
    }
    .preview,
    &.create {
      box-shadow: 1px 1px 2px 2px rgb(240, 240, 240);
    }
  }

  .more:focus {
    opacity: 1;
  }

  .item-path {
    position: absolute;
    bottom: -44px;
    left: 0px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
